/************************* Login ************************/
function login_validation(event) {

  event.preventDefault();

  var form = $('#login_form');
  var email = $('#email').val();
  var password = $('#password').val();
  var error = false;

  // espressione per la validazione dell'email
  var reg = /^(?:(?:[\w`~!#$%^&*\-=+;:{}'|,?\/]+(?:(?:\.(?:"(?:\\?[\w`~!#$%^&*\-=+;:{}'|,?\/\.()<>\[\] @]|\\"|\\\\)*"|[\w`~!#$%^&*\-=+;:{}'|,?\/]+))*\.[\w`~!#$%^&*\-=+;:{}'|,?\/]+)?)|(?:"(?:\\?[\w`~!#$%^&*\-=+;:{}'|,?\/\.()<>\[\] @]|\\"|\\\\)+"))@(?:[a-zA-Z\d\-]+(?:\.[a-zA-Z\d\-]+)*|\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])$/;

  $('.form-control').removeClass('has-error');
  $('.help-block').remove();
  $('.ajax-check').remove();

  // controllo se i campi sono vuoti
  if (email == '') {
    $('#email').after('<p class="ajax-check text-danger">Questo campo è obbligatorio</p>');
    error = true;
  } else {
    if (reg.test(email) == false) {
      $('#email').after('<p class="ajax-check text-danger">Email non valida</p>');
      error = true;
    }
  }

  if (password == '') {
    $('#password').after('<p class="ajax-check text-danger">Questo campo è obbligatorio</p>');
    error = true;
  }

  if (error == true) {
    return false;
  } else {
    form[0].submit();
  }
}

/************************* /Login ************************/

/************************* Required Check ************************/
/**
 * Controlla i form con classe required_check per i campi required e notifica gli eventuali errori
 *
 *
 * @return {bool}
 */
$('.required_check').submit(function (event) {

  var errors = Array();

  $(this).find('.required').each(function () {

    if ($(this).val() == '') {
      field_label = $('label[for=' + $(this).attr('id') + ']').clone();
      field_label.find('span, small').remove();
      field_label = field_label.text();
      field_label = field_label.replace(':', '');
      if ($.trim($(this).val()) == '' || $.trim($(this).val()) == 'NULL') {
        errors.push('Il campo <strong>' + field_label + '</strong> è richiesto.');
        $(this).addClass('has-error').focus();
      }
    }
  })

  if (errors.length > 0) {
    text = '<p class="warning-error">Correggere i seguenti errori prima di proseguire:</p>';
    for (i = 0; i < errors.length; i++) {
      text += '<p> - ' + errors[i] + '</p>';
    }
    noty_warning(text, 'no_buttons');
    event.preventDefault();
    return false;
  } else {
    return true;
  }
})
/************************* /Required Check ************************/

/*******************************  AJAX FORM  ********************************/

$(document).on('submit', '.ajax_form', function (event) {

  var $btn = $(this).find('[type="submit"]');
  $btn.prop('disabled', true);

  var action = $(this).attr('action');
  var data = $(this).serializeArray();
  var success_msg = $(this).attr('data-success_msg');
  var page_reload = $(this).attr('data-page_reload');
  var file_upload = $(this).attr('data-file_upload');
  var redirect_to = $(this).attr('data-redirect_to');
  var success_callback = $(this).attr('data-success_callback');
  var confirm = $(this).attr('data-confirm');
  var confirm_msg = $(this).attr('data-confirm_msg');
  var display_log = $(this).attr('data-display_log');
  var log_container = $(this).attr('data-log_container');
  var close_modal = $(this).attr('data-close_modal');
  var ajax_options = {};

  var waiting_noty = new Noty({
    type: 'info',
    layout: 'topRight',
    theme: 'bootstrap-v3',
    timeout: 300000,
    text: 'Attendere prego',
  }).show();

  if (!success_msg || typeof success_msg == 'undefined') success_msg = 'Operazione conclusa con successo';
  if (!redirect_to || typeof redirect_to == 'undefined') redirect_to = false;
  if (!success_callback || typeof success_callback == 'undefined') success_callback = false;
  if (!confirm || typeof confirm == 'undefined') confirm = false;
  if (!confirm_msg || typeof confirm_msg == 'undefined') confirm_msg = 'Sei sicuro di voler procedere?';
  if (!display_log || typeof display_log == 'undefined') display_log = false;
  if (!log_container || typeof log_container == 'undefined') log_container = '';
  if (!close_modal || typeof close_modal == 'undefined') close_modal = false;

  page_reload = page_reload || false;
  file_upload = file_upload || false;

  event.preventDefault();

  if (file_upload) {
    var data = new FormData($(this)[0]);
    ajax_options = {
      url: action,
      cache: false,
      contentType: false,
      processData: false,
      type: 'POST',
      dataType: 'JSON',
      data: data,
    };
  } else {
    ajax_options = {
      url: action,
      type: 'POST',
      dataType: 'JSON',
      data: data,
    };
  }

  var ajax_function = function () {
    $.ajax(ajax_options)
      .done(function (response) {
        if (response.message == 'OK') {
          noty_success(success_msg);
          if (success_callback && typeof window[success_callback] === 'function') {
            window[success_callback].call();
          } else if (redirect_to) {
            window.location.replace(redirect_to);
          } else if (page_reload) {
            window.location.reload();
          }
        } else {
          if (display_log) {
            $(log_container).html('');
            var html = $(log_container).html(response.message).text();
            $(log_container).html(html);
          } else {
            noty_warning(response.message);
          }
        }
        if (close_modal) $('.modal').modal('hide');
      })
      .fail(function (data) {
        gestione_errore_ajax(data);
      })
      .always(function () {
        waiting_noty.close();
        $btn.prop('disabled', false);
      });
  }

  if (confirm) {
    noty_confirm(confirm_msg, 'Sì', 'No', ajax_function);
  } else {
    ajax_function();
  }

});

/*******************************  /AJAX FORM  ********************************/

/************************* BTN ELIMINA NOTY CONFIRM ************************/

$(document).on('click', '.btn_elimina', function () {

  var id = $(this).attr('data-id');
  var url = $(this).attr('data-url');
  var msg = $(this).attr('data-msg');
  var success_msg = $(this).attr('data-success_msg');
  var page_reload = $(this).attr('data-page_reload');
  var success_callback = $(this).attr('data-success_callback');

  if (!success_msg || typeof success_msg == 'undefined') success_msg = 'Elemento eliminato con successo';
  if (!success_callback || typeof success_callback == 'undefined') success_callback = false;
  page_reload = page_reload || false;

  event.preventDefault();

  var ajax_function = function () {

    var waiting_noty = new Noty({
      type: 'info',
      layout: 'topRight',
      theme: 'bootstrap-v3',
      timeout: 5000,
      text: 'Attendere prego',
    }).show();

    $.ajax({
      url: url,
      type: 'POST',
      dataType: 'JSON',
      data: {id: id},
    })
      .done(function (response) {
        if (response.message == 'OK') {
          noty_success(success_msg);
          if (success_callback && typeof window[success_callback] === 'function') {
            window[success_callback].call();
          } else if (page_reload) {
            window.location.reload();
          }
        } else {
          noty_warning(response.message);
        }
      })
      .fail(function (data) {
        gestione_errore_ajax(data);
      })
      .always(function () {
        waiting_noty.close();
      });
  }

  if (msg == '') {
    msg = "Eliminare l'elemento selezionato?";
  }

  noty_confirm(msg, 'Sì', 'No', ajax_function);

});

/************************* /BTN ELIMINA NOTY CONFIRM ************************/

function gestione_errore_ajax(data) {
  var response = data.responseJSON;
  if (typeof response == 'object') {
    var errors = '';
    if ('errors' in response) {
      $.each(response.errors, function (index, error) {
        errors += '<p>' + error + '</p>';
      });
    } else if ('message' in response) {
      errors = response.message;
    }

    response = errors;
  }
  noty_error(response);
}