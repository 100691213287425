/*******************************  NOTY  ********************************/

var noty_error = function (message) {
  new Noty({
    type: 'error',
    layout: 'topRight',
    theme: 'bootstrap-v3',
    timeout: 5000,
    text: message,
  }).show();
};

var noty_warning = function (message) {
  new Noty({
    type: 'warning',
    layout: 'topRight',
    theme: 'bootstrap-v3',
    timeout: 5000,
    text: message,
  }).show();
};

var noty_success = function (message) {
  new Noty({
    type: 'success',
    layout: 'topRight',
    theme: 'bootstrap-v3',
    timeout: 5000,
    text: message,
  }).show();
};

var noty_info = function (message) {
  new Noty({
    type: 'info',
    layout: 'topRight',
    theme: 'bootstrap-v3',
    timeout: 5000,
    text: message,
  }).show();
};

/**
 * Genera un alert che chiede la conferma per l'azione. E' possibile passare un URL o una callback
 *
 * @param {string} confirm_text
 * @param {string} yes_button
 * @param {string} no_button
 * @param {string|function} action
 * @param {function} cancel_action
 * @returns {boolean}
 */
var noty_confirm = function (confirm_text, yes_button, no_button, action, cancel_action) {

  confirm_text = confirm_text || 'Sei sicuro di voler proseguire?';
  yes_button = yes_button || 'Si';
  no_button = no_button || 'No';

  if (typeof action === 'undefined') return false;

  var n = new Noty({
    text: confirm_text,
    layout: 'center',
    modal: true,
    buttons: [
      Noty.button(yes_button, 'btn btn-success pull-right', function () {
        if (typeof action === 'function') {
          action();
        } else {
          window.location.href = action;
        }
        n.close();
      }, {id: 'button1', 'data-status': 'ok'}),
      Noty.button(no_button, 'btn btn-danger', function () {
        if (typeof cancel_action === 'function') {
          cancel_action();
        } else if (typeof cancel_action !== 'undefined') {
          window.location.href = cancel_action;
        }
        n.close();
      })
    ]
  }).show();

};

/*******************************  /NOTY  ********************************/

$(document).ready(function () {

  $.ajaxSetup({
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
  });

  /************************* SELECT 2 ************************/

  if ($('.select2').length) {
    $('.select2').select2({
      placeholder: "Seleziona una opzione",
    });
  }

  /************************* /SELECT 2 ************************/

  /************************* MULTISELECT ************************/

  if ($('.multiselect').length) {
    $('.multiselect').multiselect({
      enableFiltering: true,
      filterPlaceholder: 'Cerca...',
      buttonWidth: '100%',
      maxHeight: 400,
      disableIfEmpty: true,
      includeSelectAllOption: true,
      selectAllText: 'Seleziona tutto',
      disabledText: 'Nessuna opzione',
      buttonText: function (options, select) {
        if (options.length === 0) {
          return 'Seleziona una opzione';
        }
        else if (options.length === 1) {
          return '1 opzione selezionata';
        } else {
          return options.length + ' opzioni selezionate';
        }
      }
    });
  }

  /************************* /MULTISELECT ************************/

  /************************* DATATABLE ************************/

  var orderable = true;

  if ($('.datatable').attr('data-orderable') == 'false') {
    var orderable = false;
  }

  if ($('.datatable').length) {
    $('.datatable').DataTable({
      language: {
        "emptyTable": "Nessun elemento disponibile",
        "info": "Elementi da _START_ a _END_ di _TOTAL_",
        "infoEmpty": "0 elementi",
        "infoFiltered": "(da un totale di _MAX_)",
        "lengthMenu": "Mostra _MENU_ elementi",
        "loadingRecords": "Caricamento...",
        "processing": "Caricamento...",
        "search": "Cerca ",
        "zeroRecords": "Nessun elemento corrispondente",
        "paginate": {
          "first": "Inizio",
          "last": "Fine",
          "next": "Successiva",
          "previous": "Precedente"
        },
        "aria": {
          "sortAscending": ": ordine crescente",
          "sortDescending": ": ordine decrescente"
        }
      },
      columnDefs: [
        {targets: 'unorderable', orderable: false},
      ],
      lengthMenu: [[10, 30, 50, -1], [10, 30, 50, "Tutti"]],
      pageLength: 30,
      ordering: orderable,
    });
  }

  $('.order-desc').trigger('click');

  /************************* /DATATABLE ************************/

  /************************* BOOTSTRAP WYSIWYG ************************/

  if ($('.editor').length) {
    $('.editor').wysihtml5({
      locale: 'it-IT',
    });
  }

  /************************* /BOOTSTRAP WYSIWYG ************************/

  /*********************** BOOTSTRAP DATERANGEPICKER  ************************/

  if ($('.datepicker').length) {
    $('.datepicker').daterangepicker({
      locale: {
        format: 'DD-MM-YYYY',
        "separator": " - ",
        "applyLabel": "Applica",
        "cancelLabel": "Annulla",
        "fromLabel": "Da",
        "toLabel": "A",
        "customRangeLabel": "Custom",
        "daysOfWeek": [
          "Do",
          "Lu",
          "Ma",
          "Me",
          "Gi",
          "Ve",
          "Sa"
        ],
        "monthNames": [
          "Gennaio",
          "Febbraio",
          "Marzo",
          "Aprile",
          "Maggio",
          "Giugno",
          "Luglio",
          "Agosto",
          "Settembre",
          "Ottobre",
          "Novembre",
          "Dicembre"
        ],
        "firstDay": 1
      },
      singleDatePicker: true,
    });
  }

  if ($('.datepicker-scadenza-account').length) {
    $('.datepicker-scadenza-account').daterangepicker({
      locale: {
        format: 'DD-MM-YYYY',
        "separator": " - ",
        "applyLabel": "Applica",
        "cancelLabel": "Annulla",
        "fromLabel": "Da",
        "toLabel": "A",
        "customRangeLabel": "Custom",
        "daysOfWeek": [
          "Do",
          "Lu",
          "Ma",
          "Me",
          "Gi",
          "Ve",
          "Sa"
        ],
        "monthNames": [
          "Gennaio",
          "Febbraio",
          "Marzo",
          "Aprile",
          "Maggio",
          "Giugno",
          "Luglio",
          "Agosto",
          "Settembre",
          "Ottobre",
          "Novembre",
          "Dicembre"
        ],
        "firstDay": 1
      },
      startDate: get_today_date(false, false, 1),
      minDate: get_today_date(),
      singleDatePicker: true,
    });
  }

  if ($('.datepicker-filtro-log').length) {
    $('.datepicker-filtro-log').daterangepicker({
      locale: {
        format: 'DD-MM-YYYY',
        "separator": " / ",
        "applyLabel": "Applica",
        "cancelLabel": "Annulla",
        "fromLabel": "Da",
        "toLabel": "A",
        "customRangeLabel": "Custom",
        "daysOfWeek": [
          "Do",
          "Lu",
          "Ma",
          "Me",
          "Gi",
          "Ve",
          "Sa"
        ],
        "monthNames": [
          "Gennaio",
          "Febbraio",
          "Marzo",
          "Aprile",
          "Maggio",
          "Giugno",
          "Luglio",
          "Agosto",
          "Settembre",
          "Ottobre",
          "Novembre",
          "Dicembre"
        ],
        "firstDay": 1
      },
      autoUpdateInput: false,
    });
  }

  $('.datepicker-filtro-log').on('apply.daterangepicker', function (ev, picker) {
    $(this).val(picker.startDate.format('DD-MM-YYYY') + ' / ' + picker.endDate.format('DD-MM-YYYY'));
  });

  $('.datepicker-filtro-log').on('cancel.daterangepicker', function (ev, picker) {
    $(this).val('');
  });

  /*********************** /BOOTSTRAP DATERANGEPICKER  ************************/

});

/*******************************  TODAY DATE  ********************************/

function get_today_date(more_days, more_months, more_years) {

  var more_days = more_days || 0;
  var more_months = more_months || 0;
  var more_years = more_years || 0;

  var today = new Date();
  var dd = today.getDate() + more_days;
  var mm = today.getMonth() + 1 + more_months; //January is 0 so has to add 1
  var yyyy = today.getFullYear() + more_years;

  if (dd < 10) {
    dd = '0' + dd
  }

  if (mm < 10) {
    mm = '0' + mm
  }

  today = dd + '-' + mm + '-' + yyyy;

  return today;
}

/*******************************  /TODAY DATE  ********************************/